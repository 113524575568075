import React from 'react'

export const Service = () => {
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-lg-4'>
                    <div class="card">
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGxoPbPiOG9KHwwltK5Dyx2eBhm6C64XbKI97KMy94Dw&s" class="card-img-top" alt="..." />
                        <div class="card-body">
                            <h5 class="card-title">IT Consultancy & Placement</h5>
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div class="card">
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5Ye2ksp7xx9PTm60WRuk8l3qWDzFuDHfv-M4HCa2Img&s" class="card-img-top" alt="..." />
                        <div class="card-body">
                            <h5 class="card-title">Software And Training Institute</h5>
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div class="card">
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTA3YnUuxnyFvtkUe1u6mfSJbCVwvnKlO-TWOcMtnaIyQ&s" class="card-img-top" alt="..." />
                        <div class="card-body">
                            <h5 class="card-title">Software Product Building</h5>
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
