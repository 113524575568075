import logo from './logo.svg';
import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RoomID } from './RoomID.jsx';
import { Videoconference } from './Videoconference';
import { Navbar } from './Navbar.js';
import { Aboutus } from './Aboutus.js';
import { Service } from './Service.js';
import { Footer } from './Footer.js';
import { useState } from 'react';

const App = () => {
  const [username,setUsername] =useState('lnskills');

  return (
    <div className="App">
      <BrowserRouter>
      <Navbar></Navbar>
        <Routes>
          <Route path='/' element={<RoomID setUsername={setUsername}></RoomID>}></Route>
          <Route path='/videoconference/:roomID' element={<Videoconference username={username}></Videoconference>}></Route>
          <Route path='/about' element={<Aboutus></Aboutus>}></Route>
          <Route path='/service'  element={<Service></Service>}></Route>
        </Routes>
        <Footer></Footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
