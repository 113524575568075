import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import React from 'react'
import { useParams } from 'react-router-dom'

export const Videoconference = ({username}) => {
  const { roomID } = useParams();
  const popvideo = async (element) => {
    const appID =  1880426194;
    const serverSecret = '93aa608f2b7a494d16b23d27bd419980';
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID, Date.now().toString(), username);
    const zp = ZegoUIKitPrebuilt.create(kitToken);
    zp.joinRoom({
      container: element,
      scenario:{
      mode: ZegoUIKitPrebuilt.VideoConference

    }

})

}

return (
  <div ref={popvideo}>Videoconference</div>
)
}
