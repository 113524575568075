import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

export const RoomID = ({setUsername}) => {
    const [RoomID,setRoomid] =useState('');

    console.log(RoomID);
    const navigation = useNavigate();
    const navigationafterclick = ()=>{

      navigation(`videoconference/${RoomID}`)
    }
  
  return (
    <div>
   
    <div class="containerbox">
        <div class="form-section">
            <img src={require("./images/heroimage.jpg")} alt="" className='w-25'/>
            <h1>Video calls with anyone, anywhere</h1>
            <h3>Stay connected and collaborate with friends, family, and colleagues no matter where you are.</h3>
            <form onSubmit={()=>navigationafterclick()}>
            <input type='number' placeholder='Enter Your Given Skill Id' onChange={(event)=>setRoomid(event.target.value)} required/>
            <input type='text' placeholder='Enter Your Name' onChange={(event)=>setUsername(event.target.value)} required/>

        <button className='button1' type='submit' >Enter</button>
            </form>
        </div>
        <div class="carousel-section">
            <div class="owl-carousel owl-theme">
                <img src={require('./images/unnamed.webp')}alt="Image 1" class="image" width="100%"/>
            </div>
        </div>
    </div>

    </div>
  )
}
