import React from 'react'

export const Aboutus = () => {
  return (
    <div>
          <div class="containerbox">
        <div class="form-section">
            <h1>About us</h1>
            <p>In today's digital age, video consultations have become an invaluable tool for accessing healthcare from the comfort of your own home. By leveraging platforms like Zoom or telemedicine apps, patients can connect with healthcare providers seamlessly, eliminating the need for travel and minimizing exposure to germs. These consultations offer a convenient alternative to traditional in-person visits, especially for individuals with mobility limitations or those living in remote areas.</p>
            <p>Moreover, video consultations enhance accessibility to specialist care, enabling patients to consult with experts regardless of geographical barriers. They also promote continuity of care by allowing patients to follow up with their healthcare providers regularly. Privacy and security measures are paramount in video consultations, ensuring confidentiality and compliance with healthcare regulations. With the integration of advanced features like file sharing and virtual examinations, video consultations are evolving to provide comprehensive healthcare experiences.</p>

        </div>
        <div class="carousel-section">
            <div class="owl-carousel owl-theme">
                <img src='https://media.istockphoto.com/id/1355302972/photo/african-american-customer-woman-talking-to-support-service-employee.jpg?s=612x612&w=0&k=20&c=oqDbA9Isz4RRdTln7BfS3yoSxN1VeY5lCRfv3u6_bAI=' alt="Image 1" class="image" width="100%"/>
            </div>
        </div>
    </div>
    </div>
  )
}
