import React from 'react'

export const Footer = () => {
  return (
    <div className='blue'>
        <div className='containter mt-5'>
            <h5 className='text-center py-2'>© Copyright 2024. All Rights Reserved by <a href='https://www.lnskills.com/'> Learn New Skills</a></h5>
        </div>
    </div>
  )
}
